import axios from 'axios'
import { useAuthStore } from '../stores'
import { MD5 } from './crypto'

/**
 * 创建 axios 实例
 */

const defaultExpire = import.meta.env.AI365_API_TIMEOUT * 1000
const service = axios.create({
    baseURL: '/api',
    timeout: defaultExpire
})

/**
 * 增加请求拦截器
 */
service.interceptors.request.use(
    (config) => {
        const app = {
            ID: import.meta.env.AI365_APP_CLIENT,
            timestamp: Date.now()
        }
        const appKey = MD5(app)
        const access_token = useAuthStore().access_token
        const refresh_token = useAuthStore().refresh_token
        const is_Authorized = useAuthStore().is_Authorized
        if (access_token != undefined && access_token != '' && is_Authorized == true) {
            config.headers.Authorization = 'Bearer ' + access_token
            if (refresh_token != undefined && refresh_token != '') config.headers.set('Refresh-Token', refresh_token)
        }
        config.headers.set('Client', app.ID)
        config.headers.set('Signature', appKey)
        config.headers.set('Timestamp', app.timestamp)
        return config
    },
    (error) => {
        return Promise.reject(error.response)
    }
)

/**
 * 增加响应拦截器
 */
service.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        const err = error.response
        const status = err.status
        if (status === 401 && useAuthStore().is_Authorized == true) {
            setTimeout(() => {
                useAuthStore().logout()
            }, 100)
        }
        return Promise.reject(err)
    }
)

export function get(config: any): Promise<any> {
    return service.request({ ...config, method: 'GET' })
}

export function post(config: any): Promise<any> {
    return service.request({ ...config, method: 'POST' })
}

import { post } from '../utils'

interface IHttp {
    data: any
    status: number
    statusText: string
}

export async function send_validation_email(email: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/email/signup',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {
            recipinent: email
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })

    return http
}

export async function send_reset_email(email: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }

    await post({
        url: '/email/reset',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {
            recipinent: email
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })

    return http
}

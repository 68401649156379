import type { App, AppContext } from 'vue'
import MessageList from './MessageList.vue'
import MessageManager from './instance'
import type { IMessageConfig, IMessageVariant } from './types'

let msg: MessageManager
const variants = ['info', 'success', 'warning', 'error', 'text'] as const

const message = variants.reduce((pre, value) => {
    pre[value] = (config: string | IMessageConfig, appContext?: AppContext) => {
        if (typeof config === 'string') {
            config = { text: config as string }
        }

        const _config: IMessageConfig = { variant: value as IMessageVariant, ...(config as IMessageConfig) }
        if (!msg) {
            msg = new MessageManager(appContext)
        }
        return msg!.add(_config as IMessageConfig)
    }
    return pre
}, {} as any)

const Message = Object.assign({
    ...message,
    install: (app: App) => {
        app.component(MessageList.name as string, MessageList)
    }
})

export default Message

import { ref, shallowRef, onMounted, onUnmounted, type Ref } from 'vue'
import router from '@/libs/routers'
import type { RouteRecordNormalized } from 'vue-router'

export const topMenu = shallowRef<RouteRecordNormalized[]>()
export const subMenu = shallowRef<RouteRecordNormalized[]>()
export const showMobileMenu = ref(false)

export function useMenu() {
    function getTopMenu() {
        topMenu.value = router.getRoutes().filter((item) => {
            return (item.meta?.order as number) > 0 && item.meta?.level == 'top'
        })
    }

    function toggleMobileMenu() {
        showMobileMenu.value = !showMobileMenu.value
    }

    function getSubMenu() {
        subMenu.value = router.getRoutes().filter((item) => {
            return (item.meta?.order as number) > 0 && item.meta?.level == 'sub'
        })
    }

    return { getTopMenu, getSubMenu, toggleMobileMenu }
}

export function useClickOutSide(elementRef: Ref<null | HTMLElement>) {
    const isClickOutSide = ref(false)
    const handler = (e: MouseEvent) => {
        if (elementRef.value) {
            if (elementRef.value.contains(e.target as HTMLElement)) {
                isClickOutSide.value = false
            } else {
                isClickOutSide.value = true
            }
        }
    }
    onMounted(() => {
        document.addEventListener('click', handler, true)
    })

    onUnmounted(() => {
        document.removeEventListener('click', handler, true)
    })
    return isClickOutSide
}

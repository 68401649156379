import { post, get, localDateTime } from '../utils'
import {} from '../utils/axios'
import type { IChatPreset } from '@/types/openAI.types'
import type { IUser, IChatUsage, IDalleUsage } from '@/types/user.types'
import { send_validation_email } from '../apis'

interface IHttp {
    data: any
    status: number
    statusText: string
}

export async function signup(email: string, password: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/signup',
        data: {
            email: email,
            password: password,
            username: '多啦A梦',
            avatar: '',
            is_deleted: false
        },
        headers: {
            Accept: 'application/json'
        }
    })
        .then(async () => {
            await send_validation_email(email)
                .then(
                    (res) =>
                        (http = {
                            data: res.data,
                            status: res.status,
                            statusText: res.statusText
                        })
                )
                .catch((error) => {
                    http = {
                        data: error.data,
                        status: error.status,
                        statusText: error.statusText
                    }
                })
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function login(email: string, password: string) {
    const form = new FormData()
    form.append('username', email)
    form.append('password', password)
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/login',
        data: form,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function refreshToken() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/refresh',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getMe() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/me',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })

    return http
}

export async function getMyQuotas() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/quotas',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })

    return http
}

export async function updateMyProfile(user: IUser) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/update',
        data: {
            username: user.name,
            avatar: user.avatar
        },
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })

    return http
}

export async function updateMyPassword(oldPassword: string, newPassword: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/password/update',
        data: {
            old_password: oldPassword,
            new_password: newPassword
        },
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })

    return http
}

export async function resetMyPassword(password: string, callbackToken: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/password/reset',
        params: {
            password: password,
            token: callbackToken
        },
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            console.log(error)
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function createChatUsage(chatUsage: IChatUsage) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/chat/usage/create',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {
            user_id: chatUsage.user_id,
            chat_id: chatUsage.chat_id,
            model: chatUsage.model,
            prompt_content: chatUsage.prompt_content,
            completion_content: chatUsage.completion_content,
            consumed_total_token: chatUsage.consumed_total_token,
            consumed_prompt_token: chatUsage.consumed_prompt_token,
            consumed_completion_token: chatUsage.consumed_completion_token
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function signup_validate(email: string, password: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/email/validate',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            email: email,
            password: password
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getMyGPTUsageCount() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/chat/usage',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            current: localDateTime(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getGPTUsageDetail() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/chat/usage/detail',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getGPTUsageByModel(model: string) {
    let http: IHttp = { data: [], status: 0, statusText: '' }
    await get({
        url: '/user/chat/usage/model',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            model: model
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function createPromptPreset(preset: IChatPreset) {
    let http: IHttp = { data: [], status: 0, statusText: '' }
    await post({
        url: '/user/preset/prompt/create',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {
            title: preset.title,
            description: preset.description,
            content: preset.content
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function deletePromptPreset(id: number) {
    let http: IHttp = { data: [], status: 0, statusText: '' }
    await post({
        url: '/user/preset/prompt/delete',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            preset_id: id
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getPromptPreset() {
    let http: IHttp = { data: [], status: 0, statusText: '' }
    await get({
        url: '/user/preset/prompt',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getMyBalance() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/balance',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            current: localDateTime(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getModelBalance(model: string) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/balance/model',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            model: model,
            current: localDateTime(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

// Deprecated
export async function createDalleUsage(dalleUsage: IDalleUsage) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/dalle/usage/create',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {
            user_id: dalleUsage.user_id,
            model: dalleUsage.model,
            url: dalleUsage.url,
            b64_json: dalleUsage.b64_json,
            revised_prompt: dalleUsage.revised_prompt,
            is_favorite: dalleUsage.is_favorite,
            is_deleted: dalleUsage.is_deleted,
            created: dalleUsage.created_at
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function addFavorite(dalleUsageId: number) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/dalle/favorite/add',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            id: dalleUsageId
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function deleteFavorite(dalleUsageId: number) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/user/dalle/favorite/delete',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            id: dalleUsageId
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getMyFavorites() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/dalle/favorites',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

export async function getMyFavorite(favorteId: number) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/dalle/favorite/q',
        Headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            id: favorteId
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
        })
    return http
}

import { ls, ss } from '../../useStorage'
import type { IChatOption } from '@/types/openAI.types'

export type ChatState = {
    chatOption: IChatOption
}

export function defaultState(): ChatState {
    return {
        chatOption: {
            id: 0,
            model: 'gpt-3.5-turbo',
            max_tokens: 500,
            temperature: 0.3,
            frequency_penalty: 0,
            presence_penalty: 0,
            context_num: 1,
            stop: ['\nuser:', '\nassistant:', '\nsystem:'],
            stream: false,
            user: ''
        }
    }
}

export function getState(storageType: 'ls' | 'ss', encryption: boolean): ChatState {
    let localState: ChatState | undefined
    if (storageType === 'ls') {
        localState = ls.get('chatOption', encryption)
    } else {
        localState = ss.get('chatOption', encryption)
    }
    return { ...defaultState(), ...localState }
}

export function setState(storageType: 'ls' | 'ss', setting: ChatState, encryption: boolean): void {
    if (storageType === 'ls') {
        ls.set('chatOption', setting, encryption)
    } else {
        ss.set('chatOption', setting, encryption)
    }
}

export function resetState(storageType: 'ls' | 'ss'): void {
    if (storageType === 'ls') {
        ls.delete('chatOption')
    } else {
        ss.delete('chatOption')
    }
}

import { post, get, localDateTime } from '../utils'
import type { IDalleOption, IChatOption, IChatRequest } from '@/types/openAI.types'

interface IHttp {
    data: any
    status: number
    statusText: string
}

export async function chat(prompt: IChatRequest[], options: IChatOption) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/openai/chat',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            current: localDateTime(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        },
        timeout: import.meta.env.AI365_API_TIMEOUT * 1000,
        data: {
            request: {
                messages: prompt,
                options: {
                    model: options.model,
                    max_tokens: options.max_tokens,
                    temperature: options.temperature,
                    top_p: options.top_p,
                    frequency_penalty: options.frequency_penalty,
                    presence_penalty: options.presence_penalty,
                    stop: options.stop,
                    stream: options.stream,
                    user: options.user
                }
            }
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })

    return http
}

export async function dalle(prompt: string, options: IDalleOption) {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await post({
        url: '/openai/dalle',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        params: {
            current: localDateTime(Date.now(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        },
        timeout: import.meta.env.AI365_API_TIMEOUT * 1000,
        data: {
            request: {
                prompt: prompt,
                options: {
                    model: options.model,
                    n: options.n,
                    quality: options.quality,
                    size: options.size,
                    style: options.style,
                    response_format: options.response_format
                }
            }
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })

    return http
}

export async function getChatOption() {
    let http: IHttp = { data: {}, status: 0, statusText: '' }
    await get({
        url: '/user/chat/option',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })
    return http
}

export async function getChatModels() {
    let http = { data: [], status: 0, statusText: '' }
    await get({
        url: '/user/models',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })
    return http
}

export async function saveChatOption(chat_option: IChatOption, chat_option_id: number) {
    let http = { data: Object, status: 0, statusText: '' }
    await post({
        url: '/user/chat/option/update',
        data: {
            model: chat_option.model,
            max_tokens: chat_option.max_tokens,
            temperature: chat_option.temperature,
            presence_penalty: chat_option.presence_penalty,
            frequency_penalty: chat_option.frequency_penalty,
            context_num: chat_option.context_num,
            preset_role: ''
        },
        params: {
            gpt_option_id: chat_option_id
        },
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        }
    })
        .then((res) => {
            http = {
                data: res.data,
                status: res.status,
                statusText: res.statusText
            }
        })
        .catch((error) => {
            http = {
                data: error.data,
                status: error.status,
                statusText: error.statusText
            }
            // throw new Error(JSON.stringify(http))
        })

    return http
}

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export function localDateTime(datetime: string | number | Date, tz?: string, format?: string) {
    if (datetime) {
        dayjs.extend(utc)
        dayjs.extend(timezone)
        dayjs.tz.setDefault('Asia/Shanghai')
        if (tz) {
            return dayjs
                .utc(datetime)
                .tz(tz)
                .format(format ? format : 'YYYY-MM-DD HH:mm:ss')
                .toString()
        } else {
            return dayjs
                .utc(datetime)
                .local()
                .format(format ? format : 'YYYY-MM-DD HH:mm:ss')
                .toString()
        }
    }
}

export const localeCurrentYear = (datetime: string | number | Date, tz?: string) => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault('Asia/Shanghai')
    if (tz) {
        return dayjs.tz(new Date(datetime), tz).year()
    } else {
        return dayjs.utc(new Date(datetime)).local().year()
    }
}

export enum IMessageVariant {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Text = 'text'
}

export interface IMessageConfig {
    id?: string
    class?: string
    variant?: IMessageVariant
    text: string
    duration?: number
    allowClose?: boolean
}

export interface IMessageItem extends IMessageConfig {}

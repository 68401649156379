import { AESE, AESD } from '../utils/crypto'

/** expire(小时) */
const session_timeout = import.meta.env.AI365_SESSION_TIMEOUT as number

type storeItem = {
    value: any
    expire?: Date
}

enum StorageType {
    ls = 'localStorage',
    ss = 'sessionStorage'
}

/**
 * Storage
 * 类型: local storage | session storage
 */
class Storages {
    storage: Storage

    constructor(type: StorageType) {
        this.storage = type === StorageType.ls ? window.localStorage : window.sessionStorage
    }

    private encrypt(data: string) {
        return AESE(data)
    }

    private decrypt(data: string) {
        return AESD(data)
    }

    /**
     * 创建 Storage
     * @param key 名称
     * @param data 数据
     * @param encryption 可选是否加密, 默认为否
     * @param expire 过期时间
     */
    set(key: string, data: any, encryption: boolean = false) {
        let stored: storeItem = { value: null, expire: undefined }
        const dateObj = new Date()
        const current = dateObj.getTime()
        const currentDate = new Date(current + session_timeout * 60 * 60 * 1000)
        stored = {
            value: data,
            expire: currentDate
        }
        const json = JSON.stringify(stored)
        this.storage.setItem(key, encryption ? this.encrypt(json) : json)
    }

    /**
     * 获取 Storage
     * @param key 名称
     * @param encryption 可选是否解密, 默认为否
     */
    get(key: string, encryption: boolean = false) {
        const json = this.storage.getItem(key)
        if (json) {
            const stored = encryption ? this.decrypt(json) : json
            const data: storeItem = JSON.parse(stored)
            if (data) {
                const now = new Date()
                if (data.expire && now > data.expire) {
                    this.delete(key)
                    return null
                } else return data.value
            } else return null
        } else return null
    }

    /**
     * 删除 Storage
     * @param key 名称
     */
    delete(key: string) {
        this.storage.removeItem(key)
    }

    /**
     * 清除所有Storage
     */
    clear() {
        this.storage.clear()
    }
}

export const ls = new Storages(StorageType.ls)
export const ss = new Storages(StorageType.ss)

import { computed, onMounted, onUnmounted, ref, reactive } from 'vue'
import Clipboard from 'clipboard'
import device from 'current-device'

export const deviceStates = reactive({
    isDesktop: computed(() => device.desktop()).value,
    isMobile: computed(() => device.mobile()).value,
    isSafariBrowserOnMobile: computed(() => {
        const UA = navigator.userAgent.toLowerCase()
        return (
            UA.indexOf('applewebkit') > -1 &&
            UA.indexOf('mobile') > -1 &&
            UA.indexOf('safari') > -1 &&
            (UA.indexOf('ipad') > -1 || UA.indexOf('iphone') > -1) &&
            UA.indexOf('linux') === -1 &&
            UA.indexOf('andriod') === -1 &&
            UA.indexOf('chrome') === -1 &&
            UA.indexOf('firefox') === -1 &&
            UA.indexOf('presto') === -1 &&
            UA.indexOf('edg') === -1
        )
    }).value
})

export function useClipboard() {
    function copy(node: string | Element | NodeListOf<Element>) {
        let status = true
        if (Clipboard.isSupported()) {
            const clipboard = new Clipboard(node)
            clipboard.on('success', function () {
                clipboard.destroy()
            })

            clipboard.on('error', function () {
                status = false
                clipboard.destroy()
            })
        }
        return status
    }

    return { copy }
}

export function useKeyboard() {
    function enterKey(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            return true
        } else {
            return false
        }
    }

    function enterCtrlKey(event: KeyboardEvent) {
        if ((event.ctrlKey || event.metaKey) && event.key == 'Enter') {
            return true
        } else {
            return false
        }
    }
    return { enterKey, enterCtrlKey }
}

//depecated
//改用vueuse的方法
export function useTouchScreen() {
    const coordinate = ref({
        startX: 0,
        startY: 0,
        moveX: 0,
        moveY: 0
    })

    function touchMoveStart(event: TouchEvent) {
        coordinate.value.startX = event.touches[0].clientX
        coordinate.value.startY = event.touches[0].clientY
    }
    function touchMoveToLeft(event: TouchEvent, distance: number = -50) {
        coordinate.value.moveX = event.touches[0].clientX
        coordinate.value.moveY = event.touches[0].clientY
        if (coordinate.value.startX - coordinate.value.moveX < 0 && coordinate.value.startX - coordinate.value.moveX <= distance) {
            return true
        }
    }

    function touchMoveToRight(event: TouchEvent, distance: number = 50) {
        coordinate.value.moveX = event.touches[0].clientX
        coordinate.value.moveY = event.touches[0].clientY
        if (coordinate.value.startX - coordinate.value.moveX > 0 && coordinate.value.startX - coordinate.value.moveX > distance) {
            return true
        }
    }

    return { touchMoveStart, touchMoveToLeft, touchMoveToRight }
}

export function usePixelRadio() {
    let dpr = window.devicePixelRatio || 1

    function update() {
        setTimeout(() => {
            dpr = window.devicePixelRatio
        }, 100)
    }

    onMounted(() => window.addEventListener('change', update))
    onUnmounted(() => window.removeEventListener('change', update))

    return { dpr }
}

export default deviceStates

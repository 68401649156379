import { defineStore } from 'pinia'
import type { userState } from './helper'
import { setState, getState, resetState } from './helper'
import { getMe } from '../../apis'

const isEncrypt = false
const storageType = 'ls'
export const useUserStore = defineStore('user', {
    state: (): userState => getState(storageType, isEncrypt),
    actions: {
        set() {
            setState(storageType, this.$state, isEncrypt)
        },

        revoke() {
            resetState(storageType)
        },

        async setUserState() {
            const user = await getMe()
            if (user.status === 200) {
                this.$state.user = {
                    id: user.data[0]['id'],
                    name: user.data[0]['username'],
                    email: user.data[0]['email'],
                    avatar: user.data[0]['avatar'],
                    created_at: user.data[0]['created_at']
                }
                this.set()
            } else return
        }
    }
})

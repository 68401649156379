import type { RouteRecordRaw, Router } from 'vue-router'
import { useAuthStoreHook } from '../stores'
import { storeToRefs } from 'pinia'
import { Pen, Paint, UserGear } from '@/assets/icons'
// import Home from '@/views/Home.vue'

// layout
// const DashLayout = () => import('@/views/layout/DashLayout.vue')
// const SignLayout = () => import('@/views/layout/SignLayout.vue')
// // page
// const Home = () => import('@/views/Home.vue')
// const Chat = () => import('@/views/Chat.vue')
// const User = () => import('@/views/User.vue')
// const Canvas = () => import('@/views/Canvas.vue')
// const Bot = () => import('@/views/Bot.vue')
// const NotFound = () => import('@/views/404.vue')
// // module page
// const SignIn = () => import('@/modules/auth/SignIn.vue')
// const SignUp = () => import('@/modules/auth/SignUp.vue')
// const VerifyEmail = () => import('@/modules/auth/VerifyEmail.vue')
// const Reset = () => import('@/modules/auth/Reset.vue')

const HomeView = () => import('@/views/HomeView.vue')
const DashboardView = () => import('@/views/DashboardView.vue')
const NotFound = () => import('@/views/NotFoundView.vue')
const AuthView = () => import('@/views/AuthView.vue')

const route404: RouteRecordRaw[] = [
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: '出错啦!'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: HomeView,
        meta: {
            title: '首页',
            level: 'top',
            order: 1
        }
    },
    // {
    //     path: '/news',
    //     name: 'news',
    //     component: HomeView,
    //     meta: {
    //         title: '新闻',
    //         level: 'top',
    //         order: 2
    //     }
    // },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        redirect: '/dashboard/chat',
        meta: {
            requireAuth: true
        },
        children: [
            {
                path: '/dashboard/chat',
                name: 'chat',
                components: {
                    dashboardRV: () => import('@/modules/openai/Chat.vue')
                },
                meta: {
                    title: '文本助手',
                    level: 'sub',
                    order: 1,
                    icon: Pen,
                    requireAuth: true
                }
            },
            {
                path: '/dashboard/canvas',
                name: 'canvas',
                components: {
                    dashboardRV: () => import('@/modules/openai/Canvas.vue')
                },
                meta: {
                    title: '绘图助手',
                    level: 'sub',
                    order: 2,
                    icon: Paint,
                    requireAuth: true
                }
            },
            {
                path: '/dashboard/user',
                name: 'user',
                components: {
                    dashboardRV: () => import('@/modules/user/User.vue')
                },
                meta: {
                    title: '我的账户',
                    level: 'sub',
                    order: 3,
                    icon: UserGear,
                    requireAuth: true
                }
            }
        ]
    },
    {
        path: '/auth',
        redirect: { path: '/auth/login' }
    },
    {
        path: '/auth/:path',
        name: 'auth',
        component: AuthView,
        meta: {
            title: '欢迎'
        }
    },
    ...route404
]

export async function setupRouterGuard(router: Router) {
    const authStore = useAuthStoreHook()
    const { auth } = storeToRefs(authStore)
    const { logout } = authStore
    if ((auth.value.expired_at as number) <= Date.now()) {
        await logout()
    }
    router.beforeEach(async (to, from, next) => {
        if (to.matched.length === 0) {
            next({ name: 'NotFound' })
        } else if (to.meta.requireAuth && auth.value.is_Authorized != true) {
            next({ path: '/auth/login' })
        } else {
            document.title = 'ai365' + '-' + to.meta.title
            next()
        }
    })
}

export default routes

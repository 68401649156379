export function useTheme() {
    function autoColorMode() {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    function toggleColorMode(mode: 'dark' | 'light') {
        if (mode == 'dark') {
            if (document.documentElement.classList.contains('dark')) {
                return
            } else {
                document.documentElement.classList.add('dark')
            }
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark')
            } else {
                return
            }
        }
    }

    return { autoColorMode, toggleColorMode }
}

export default useTheme

import { defineStore } from 'pinia'
import { getChatOption } from '../../../apis'
import { type ChatState, getState, setState, resetState } from './helper'

const isEncrypt = false
const storageType = 'ls'

export const useChatStore = defineStore('chatOption', {
    state: (): ChatState => getState(storageType, isEncrypt),
    actions: {
        set() {
            setState('ls', this.$state, isEncrypt)
        },

        update(chatOption: Partial<ChatState>) {
            this.chatOption = { ...this.chatOption, ...chatOption }
        },

        revoke() {
            resetState(storageType)
        },

        async setChatState() {
            const res = await getChatOption()
            if (res.status === 200) {
                this.$state.chatOption = {
                    id: res.data['id'],
                    model: res.data['model'],
                    max_tokens: res.data['max_tokens'],
                    temperature: res.data['temperature'] ? res.data['temperature'] : 0,
                    frequency_penalty: res.data['frequency_penalty'] ? res.data['frequency_penalty'] : 0,
                    presence_penalty: res.data['presence_penalty'] ? res.data['presence_penalty'] : 0,
                    context_num: res.data['context_num'] ? res.data['context_num'] : 1,
                    stop: res.data['stop'] ? (res.data = ['stop']) : ['\nuser:', '\nassistant:', '\nsystem:'],
                    stream: res.data['stream'] ? res.data['stream'] : false,
                    user: res.data['user']
                }
                this.set()
            }
        }
    }
})

import CryptoJS from 'crypto-js'

interface Data {
    [key: string]: any
}

const secret = CryptoJS.enc.Utf8.parse(import.meta.env.AI365_SECRET)
const iv = CryptoJS.enc.Utf8.parse(import.meta.env.AI365_IV)

function AESE(data: any) {
    if (typeof data == 'object') {
        try {
            data = JSON.stringify(data)
        } catch (error) {
            return
        }
    }
    const hex = CryptoJS.enc.Utf8.parse(data)
    const encrypted = CryptoJS.AES.encrypt(hex, secret, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })

    return encrypted.ciphertext.toString()
}

function AESD(data: any) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(data)
    const b64str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    const decrypted = CryptoJS.AES.decrypt(b64str, secret, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
}

function MD5(data: Data) {
    let str: string = ''
    for (const key in data) {
        str += data[key]
    }
    return CryptoJS.MD5(str).toString()
}

export { AESE, AESD, MD5 }

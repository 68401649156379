import { defineStore } from 'pinia'
// import { getChatOption } from '../../../apis'
import { type DalleState, getState, setState, resetState } from './helper'

const isEncrypt = false
const storageType = 'ls'

export const useDalleStore = defineStore('dalleOption', {
    state: (): DalleState => getState(storageType, isEncrypt),
    actions: {
        set() {
            setState('ls', this.$state, isEncrypt)
        },

        update(dalleOption: Partial<DalleState>) {
            this.dalleOption = { ...this.dalleOption, ...dalleOption }
        },

        revoke() {
            resetState(storageType)
        },

        setDalleState() {
            // const res = await getChatOption()
            // if (res.status === 200) {
            // 后期加入后端api获取option
            //     }
            //     this.set()
            // }

            this.$state.dalleOption = {
                model: 'dall-e-3',
                n: 1,
                quality: 'standard',
                size: '1024x1024',
                style: 'vivid',
                response_format: 'b64_json'
            }
            this.set()
        }
    }
})

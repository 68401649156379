import type { App } from 'vue'
import { piniaStore } from './helper'
export function setupPiniaStore(app: App) {
    app.use(piniaStore)
}

export * from './auth'
export * from './user'
export * from './openai/chat'
export * from './openai/canvas'

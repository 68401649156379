import '@/assets/styles/main.css'
import 'highlight.js/styles/atom-one-dark.css'

import Message from '@/components/message'
import { createApp } from 'vue'
import { setupRouter } from '@/libs/routers'
import { setupPiniaStore } from './libs/stores'

import App from './App.vue'

async function setup() {
    const app = createApp(App)
    setupPiniaStore(app)
    await setupRouter(app)
    app.use(Message)
    app.mount('#app')
}

setup()

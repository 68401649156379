<template>
    <div class="flex w-full flex-col items-center justify-center overflow-hidden bg-background text-sm text-foreground antialiased">
        <RouterView :key="routes.path"></RouterView>
    </div>
</template>
<style scoped></style>
<script setup lang="ts">
    import { onMounted, watch } from 'vue'
    import { RouterView } from 'vue-router'
    import { useTheme } from '@/composables'
    import { useRoute } from 'vue-router'

    const routes = useRoute()

    const { autoColorMode } = useTheme()

    watch(
        () => window.matchMedia('(prefers-color-scheme: dark)').matches,
        (newVal, oldVal) => {
            if (newVal !== oldVal) {
                autoColorMode()
            }
        },
        { immediate: true }
    )

    onMounted(() => {
        autoColorMode()
    })
</script>

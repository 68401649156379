import { defineStore } from 'pinia'
import { login } from '../../apis'
import type { AuthState } from './helper'
import { setState, getState, resetState } from './helper'
import { piniaStore } from '../helper'
import { useUserStore, useChatStore, useDalleStore } from '..'
import router from '../../routers'

const isEncrypt = false
const storageType = 'ss'
export const useAuthStore = defineStore('auth', {
    state: (): AuthState => {
        return getState(storageType, isEncrypt)
    },
    getters: {
        access_token(state) {
            return state.auth.access_token
        },

        refresh_token(state) {
            return state.auth.refresh_token
        },

        is_Authorized(state) {
            return state.auth.is_Authorized
        },

        expired_at(state) {
            return state.auth.expired_at
        }
    },
    actions: {
        set() {
            setState(storageType, this.$state, isEncrypt)
        },

        revoke() {
            // this.$state.auth = {
            //     email: undefined,
            //     password: undefined,
            //     access_token: undefined,
            //     refresh_token: undefined,
            //     expired_at: undefined,
            //     is_Authorized: undefined
            // }
            resetState(storageType)
        },

        async setAuthState(email: string, password: string) {
            const res = await login(email, password)
            if (res.status === 200) {
                this.$state.auth = {
                    email: email,
                    password: password,
                    access_token: res.data['AccessToken'],
                    refresh_token: res.data['RefreshToken'],
                    expired_at: new Date().getTime() + import.meta.env.AI365_SESSION_TIMEOUT * 3600 * 1000,
                    is_Authorized: true
                }
                this.set()
                return true
            } else {
                return false
            }
        },

        async logout() {
            //清理storage中的数据
            useUserStore().revoke()
            useChatStore().revoke()
            useDalleStore().revoke()
            this.revoke()

            //清理内存中的数据
            this.$reset()

            //跳转到首页
            await router.push({ path: '/auth/login' })
        }
    }
})

export function useAuthStoreHook() {
    return useAuthStore(piniaStore)
}

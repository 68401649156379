import type { App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import routes, { setupRouterGuard } from './helper'

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: () => ({ top: 0, left: 0 })
})

export async function setupRouter(app: App) {
    setupRouterGuard(router)
    app.use(router)
    await router.isReady()
}

export default router

import type { IAuth } from '@/types/user.types'
import { ls, ss } from '../useStorage'

export type AuthState = {
    auth: IAuth
}

export function defaultState(): AuthState {
    return {
        auth: {
            email: undefined,
            password: undefined,
            access_token: undefined,
            refresh_token: undefined,
            expired_at: undefined,
            is_Authorized: false
        }
    }
}

export function getState(storageType: 'ls' | 'ss', encryption: boolean): AuthState {
    let localState: AuthState | undefined
    if (storageType === 'ls') {
        localState = ls.get('auth', encryption)
    } else {
        localState = ss.get('auth', encryption)
    }
    return { ...defaultState(), ...localState }
}

export function setState(storageType: 'ls' | 'ss', state: AuthState, encryption: boolean): void {
    if (storageType === 'ls') {
        ls.set('auth', state, encryption)
    } else {
        ss.set('auth', state, encryption)
    }
}

export function resetState(storageType: 'ls' | 'ss'): void {
    if (storageType === 'ls') {
        ls.delete('auth')
    } else {
        ss.delete('auth')
    }
}

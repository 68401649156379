import { ls, ss } from '../../useStorage'
import type { IDalleOption } from '@/types/openAI.types'

export type DalleState = {
    dalleOption: IDalleOption
}

export function defaultState(): DalleState {
    return {
        dalleOption: {
            model: 'dall-e-3',
            n: 1,
            quality: 'standard',
            size: '1024x1024',
            style: 'vivid',
            response_format: 'b64_json'
        }
    }
}

export function getState(storageType: 'ls' | 'ss', encryption: boolean): DalleState {
    let localState: DalleState | undefined
    if (storageType === 'ls') {
        localState = ls.get('dalleOption', encryption)
    } else {
        localState = ss.get('dalleOption', encryption)
    }
    return { ...defaultState(), ...localState }
}

export function setState(storageType: 'ls' | 'ss', setting: DalleState, encryption: boolean): void {
    if (storageType === 'ls') {
        ls.set('dalleOption', setting, encryption)
    } else {
        ss.set('dalleOption', setting, encryption)
    }
}

export function resetState(storageType: 'ls' | 'ss'): void {
    if (storageType === 'ls') {
        ls.delete('dalleOption')
    } else {
        ss.delete('dalleOption')
    }
}

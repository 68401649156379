import type { AppContext, Ref } from 'vue'
import { createVNode, reactive, ref, render } from 'vue'
import MessageList from './MessageList.vue'
import type { IMessageConfig, IMessageItem } from './types'

// 显示消息的条数
const maxMsgs = 3

class MessageManager {
    private container: HTMLElement | null
    private readonly messageList: Ref<IMessageItem[]>

    constructor(appContext?: AppContext) {
        this.messageList = ref([])

        const wrapper = document.createElement('div')
        wrapper.setAttribute('class', 'flex flex-col')
        this.container = wrapper

        const vm = createVNode(MessageList, {
            list: this.messageList.value,
            onClose: this.remove
        })

        if (appContext) {
            vm.appContext = appContext
        }

        render(vm, this.container)
        document.body.appendChild(this.container)
    }

    add = (config: IMessageConfig) => {
        const id = config.id ?? `message_${Math.random().toString()}`
        const message: IMessageItem = reactive({ id, ...config })
        this.messageList.value.push(message)

        const len = this.messageList.value.length
        if (len > 1 && this.messageList.value[len - 1]?.duration === message.duration) {
            message.duration = message.duration ?? 3000 + 200 * len
        }

        if (len > maxMsgs) {
            this.messageList.value.splice(0, len - maxMsgs)
        }

        return {
            close: () => this.remove(id)
        }
    }

    remove = (id: string | number) => {
        for (let i = 0; i < this.messageList.value.length; i++) {
            const { id: itemId } = this.messageList.value[i]
            if (id === itemId) {
                this.messageList.value.splice(i, 1)
                break
            }
        }
    }

    clear = () => {
        this.messageList.value = []
        if (this.container) document.body.removeChild(this.container)
    }
}

export default MessageManager

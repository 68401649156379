<template>
    <div :class="mc(baseClass)">
        <CycleX v-if="props.variant == IMessageVariant.Error" class="size-5 opacity-75"></CycleX>
        <Info v-if="props.variant == IMessageVariant.Info" class="size-5 opacity-75"></Info>
        <Warning v-if="props.variant == IMessageVariant.Warning" class="size-5 opacity-75"></Warning>
        <Check v-if="props.variant == IMessageVariant.Success" class="size-5 opacity-75"></Check>
        <span class="flex-1">{{ props.text }}</span>
        <div class="inline-flex rounded-full p-0.5 opacity-75 hover:cursor-pointer hover:bg-muted/15" @click="handleClose">
            <Xmark v-if="allowClose" class="size-4"></Xmark>
        </div>
    </div>
</template>
<style scoped></style>
<script setup lang="ts" name="MessageList">
    import { ref, computed, nextTick, type PropType, onMounted, onUnmounted } from 'vue'
    import { cva } from 'class-variance-authority'
    import { mc } from '@/libs/utils'
    import { IMessageVariant } from './types'
    import { Xmark, CycleX, Info, Check, Warning } from '@/assets/icons'

    const props = defineProps({
        id: {
            type: [String, Number],
            default: ''
        },
        variant: {
            type: String as PropType<IMessageVariant>,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        duration: {
            type: Number,
            default: 3000
        },
        allowClose: {
            type: Boolean,
            default: true
        }
    })
    //消息样式
    const baseClass = computed(() => {
        return cva(['border-none outline-none min-h-12 break-words inline-flex rounded-lg items-center justify-center max-w-xl p-4 select-none text-sm space-x-4'], {
            variants: {
                indent: {
                    info: 'ring-[1px] ring-primary/20 bg-primary/70 text-white',
                    success: 'ring-[1px] ring-success/20 bg-success/70 text-white',
                    warning: 'ring-[1px] ring-warning/20 bg-warning/70 text-white',
                    error: 'ring-[1px] ring-danger/20 bg-danger/70 text-white',
                    text: 'ring-[1px] ring-foreground/10 bg-background shadow-md'
                }
            },
            defaultVariants: {
                indent: 'info'
            }
        })({
            indent: props.variant as any
        })
    })

    const emits = defineEmits<{
        (e: 'close', id: string | number): void
    }>()
    const timer = ref(0)

    function handleClose() {
        emits('close', props.id)
    }
    const init = () => {
        if (props.duration > 0) {
            timer.value = window.setTimeout(handleClose, props.duration)
        }
    }

    const clearTimer = () => {
        if (timer.value) {
            window.clearTimeout(timer.value)
            timer.value = 0
        }
    }

    onMounted(() => {
        nextTick(() => {
            init()
        })
    })

    onUnmounted(() => {
        clearTimer()
    })
</script>

import { ls, ss } from '../useStorage'
import type { IUser } from '@/types/user.types'

export type userState = {
    user: IUser
}

export function defaultState(): userState {
    return {
        user: {
            id: 0,
            name: '',
            email: '',
            avatar: '',
            created_at: ''
        }
    }
}

export function getState(storageType: 'ls' | 'ss', encryption: boolean): userState {
    let state: userState | undefined
    if (storageType === 'ls') {
        state = ls.get('user', encryption)
    } else {
        state = ss.get('user', encryption)
    }
    return { ...defaultState(), ...state }
}

export function setState(storageType: 'ls' | 'ss', state: userState, encryption: boolean): void {
    if (storageType === 'ls') {
        ls.set('user', state, encryption)
    } else {
        ss.set('user', state, encryption)
    }
}

export function resetState(storageType: 'ls' | 'ss'): void {
    if (storageType === 'ls') {
        ls.delete('user')
    } else {
        ss.delete('user')
    }
}

<template>
    <TransitionGroup name="msg" tag="div" :class="mc('fixed left-1/2 top-12 z-[9999] flex w-full -translate-x-1/2 flex-col items-center gap-2 p-2')">
        <template v-for="m in props.list" :key="`${m.id}`">
            <Message :id="m.id" :variant="m.variant" :text="m.text" :duration="m.duration" :allowClose="m.allowClose" @close="onClose"></Message>
        </template>
    </TransitionGroup>
</template>
<style scoped></style>
<script setup lang="ts" name="MessageList">
    import { type PropType } from 'vue'
    import type { IMessageItem } from './types'
    import { mc } from '@/libs/utils'
    import Message from './Message.vue'

    const props = defineProps({
        list: {
            type: Array as PropType<IMessageItem[]>,
            default: () => []
        }
    })

    const emits = defineEmits<{
        (e: 'close', id: string | number): void
    }>()

    const onClose = (id: string | number) => {
        emits('close', id)
    }
</script>
